// Home.js
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { FaEye, FaPencilAlt, FaTrash, FaSearch } from 'react-icons/fa';
import { supabase } from '../supabase';


const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
`;

const ViewCount = styled.div`
  display: flex;
  align-items: center;
  color: #888;
  font-size: 0.9em;
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const Heading = styled.h2`
  color: #222;
  text-align: center;
  margin-bottom: 40px;
  font-size: 2em;
`;

const SearchContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px 40px 10px 15px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 1em;
  outline: none;
  font-family: 'Poppins', sans-serif;
  transition: border-color 0.3s;

  &:focus {
    border-color: #28a745;
  }
`;

const SearchIcon = styled(FaSearch)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
`;

const PostList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`;

const PostCardContainer = styled.div`
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  position: relative;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const Category = styled.p`
  color: #6a1b9a;
  font-size: 0.85em;
  font-weight: bold;
  margin-bottom: 10px;
`;

const Title = styled.h3`
  color: #222;
  font-size: 1.5em;
  margin: 0 0 10px 0;
`;

const AuthorAndDate = styled.p`
  color: #888;
  font-size: 0.9em;
  margin-bottom: 20px;
  font-style: italic;
`;

const ContentPreview = styled.p`
  color: #555;
  font-size: 1em;
  margin: 0 0 20px 0;
  line-height: 1.6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const IconButton = styled.button`
  background-color: ${(props) => props.bgColor || '#e74c3c'};
  color: white;
  border: none;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${(props) => props.hoverColor || '#c0392b'};
  }
`;

// Add loading animation keyframes
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #6a1b9a;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  margin: 50px auto;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;

function Home({ incrementViewCount, deletePost, editPost }) {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [editingPost, setEditingPost] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch posts from Supabase
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const { data, error } = await supabase
          .from('posts')
          .select('*')
          .order('date', { ascending: false });

        if (error) throw error;
        setPosts(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error.message);
      }
    };

    fetchPosts();
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const filteredPosts = posts.filter((post) =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleIncrementViewCount = async (index) => {
    const post = posts[index];
    try {
      const { error } = await supabase
        .from('posts')
        .update({ views: (post.views || 0) + 1 })
        .eq('id', post.id);

      if (error) throw error;

      // Update local state
      const updatedPosts = [...posts];
      updatedPosts[index].views = (post.views || 0) + 1;
      setPosts(updatedPosts);

      // Call the incrementViewCount prop if it’s passed from a parent
      if (incrementViewCount) {
        incrementViewCount(index);
      }
    } catch (error) {
      console.error('Error updating view count:', error.message);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deletePost(id);
    } catch (error) {
      console.error('Error in handleDelete:', error);
    }
  };

  const handleEdit = (id) => {
    editPost(id);
  };

  const handleEditPost = (id) => {
    const postToEdit = posts.find((post) => post.id === id);
    console.log('Editing post ID:', id); // Debugging
    setEditingPost(postToEdit);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingPost((prev) => ({ ...prev, [name]: value }));
  };

  const saveEdit = async () => {
    try {
      const { error } = await supabase
        .from('posts')
        .update(editingPost)
        .eq('id', editingPost.id);

      if (error) throw error;

      setPosts(
        posts.map((post) =>
          post.id === editingPost.id ? editingPost : post
        )
      );
      setEditingPost(null);
    } catch (error) {
      console.error('Error saving edits:', error.message);
    }
  };

  const cancelEdit = () => {
    setEditingPost(null);
  };

  if (loading) {
    return (
      <Container>
        <Heading>Recent Posts</Heading>
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Heading onClick={handleScrollToTop}>Recent Posts</Heading>
      <SearchContainer>
        <SearchBar
          type="text"
          placeholder="Search for a post title..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <SearchIcon />
      </SearchContainer>
      <PostList>
        {filteredPosts.length > 0 ? (
          filteredPosts.map((post, index) => (
            <PostCardContainer key={post.id}>
              <Category>{post.category}</Category>
              <Title>{post.title}</Title>
              <AuthorAndDate>
                {post.author} / {post.date}
              </AuthorAndDate>
              <ContentPreview>{post.content.slice(0, 100)}...</ContentPreview>
              <ViewCount>
                <FaEye style={{ marginRight: '5px' }} />
                {post.views || 0}
              </ViewCount>
              <ButtonContainer>
                <Link
                  to={`/post/${post.id}`}
                  style={{ textDecoration: 'none' }}
                  onClick={() => handleIncrementViewCount(index)}
                >
                  <IconButton bgColor="#28a745" hoverColor="#218838">
                    <FaEye />
                  </IconButton>
                </Link>
                {deletePost && editPost && (
                  <>
                    <IconButton
                      bgColor="#f39c12"
                      hoverColor="#e67e22"
                      onClick={() => handleEdit(post.id)} // Ensure correct id is passed
                    >
                      <FaPencilAlt />
                    </IconButton>
                    <IconButton
                      bgColor="#e74c3c"
                      hoverColor="#c0392b"
                      onClick={() => handleDelete(post.id)}
                    >
                      <FaTrash />
                    </IconButton>
                  </>
                )}
              </ButtonContainer>
            </PostCardContainer>
          ))
        ) : (
          <p>No posts found.</p>
        )}
      </PostList>
    </Container>
  );
}

export default Home;