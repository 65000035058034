import React, { useState } from 'react';
import styled from 'styled-components';
import { supabase } from '../supabase';


const FormContainer = styled.div`
  padding: 40px;
  max-width: 600px;
  margin: 120px auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  font-size: 1em;
  outline: none;
  transition: box-shadow 0.3s, border-color 0.3s;

  &::placeholder {
    color: #bbb;
    font-style: italic;
  }

  &:focus {
    border-color: #4caf50;
    box-shadow: 0 0 10px #4caf50, 0 0 20px #ffffff;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  font-size: 1em;
  outline: none;
  transition: box-shadow 0.3s, border-color 0.3s;

  &::placeholder {
    color: #bbb;
    font-style: italic;
  }

  &:focus {
    border-color: #4caf50;
    box-shadow: 0 0 10px #4caf50, 0 0 20px #ffffff;
  }
`;

const SubmitButton = styled.button`
  background: linear-gradient(90deg, #4caf50, #66bb6a);
  border: none;
  color: #fff;
  padding: 15px 30px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  font-family: 'Poppins', sans-serif;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 10px rgba(76, 175, 80, 0.3);
  }
`;

const ErrorMessage = styled.p`
  color: #e74c3c;
  font-size: 0.9em;
  margin-top: 10px;
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

function NewPost({ addPost }) {
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [author, setAuthor] = useState('');
  const [date, setDate] = useState('');
  const [category, setCategory] = useState('');

  const handlePasswordCheck = (e) => {
    e.preventDefault();
    if (password === 'makepost!') {
      setIsAuthenticated(true);
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Check if fields are being captured correctly
    console.log('Form Submitted');
    console.log('Title:', title);
    console.log('Author:', author);
    console.log('Date:', date);
    console.log('Category:', category);
    console.log('Content:', content);

    const newPost = {
      title,
      content,
      author,
      date,
      category,
      views: 0, // Initialize views to 0
    };

    try {
      // Attempt to insert the new post into Supabase
      const { data, error } = await supabase.from('posts').insert([newPost]);
  
      if (error) {
        console.error('Supabase Error:', error); // Log the full error object
        alert(`Failed to add post. Error: ${error.message}`);
        return; // Exit if there's an error
      }
  
      console.log('Post added successfully:', data);
  
      // Clear form fields and update the UI
      setTitle('');
      setContent('');
      setAuthor('');
      setDate('');
      setCategory('');
      alert('Post added successfully!');
    } catch (err) {
      console.error('Unexpected Error:', err.message);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  return (
    <FormContainer>
      {!isAuthenticated ? (
        <form onSubmit={handlePasswordCheck}>
          <Input
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <SubmitButton type="submit">Authenticate</SubmitButton>
          <ErrorMessage show={error}>Incorrect password! Please try again.</ErrorMessage>
        </form>
      ) : (
        <form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="Post Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Author"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          />
          <Input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            required
          />
          <TextArea
            placeholder="Post Content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows="6"
            required
          />
          <SubmitButton type="submit">Add Post</SubmitButton>
        </form>
      )}
    </FormContainer>
  );
}

export default NewPost;