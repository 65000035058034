// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GlobalStyles from './styles/GlobalStyles';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import NewPost from './components/NewPost';
import PostDetails from './components/PostDetails';
import EditFormModal from './components/EditFormModal';
import { supabase } from './supabase';

function App() {
  const [posts, setPosts] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [editingPost, setEditingPost] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const { data, error } = await supabase
          .from('posts')
          .select('*')
          .order('date', { ascending: false });

        if (error) throw error;
        setPosts(data);
      } catch (error) {
        console.error('Error fetching posts:', error.message);
      }
    };

    fetchPosts();
  }, []);

  const handleLogin = () => setIsAdmin(true);
  const handleLogout = () => setIsAdmin(false);

  const addPost = async (newPost) => {
    try {
      const { data, error } = await supabase.from('posts').insert([newPost]);
      if (error) throw error;

      setPosts([data[0], ...posts]);
    } catch (error) {
      console.error('Error adding post:', error.message);
    }
  };

  const deletePost = async (id) => {
    console.log('Starting delete function with ID:', id);

    try {
      // Delete query
      const { error } = await supabase
        .from('posts')
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Delete error:', error);
        throw error;
      }

      // Verify deletion
      const { data: verifyData } = await supabase
        .from('posts')
        .select('*')
        .eq('id', id);

      if (verifyData && verifyData.length > 0) {
        throw new Error('Delete failed - post still exists');
      }

      // Update local state only after successful deletion
      setPosts(posts.filter(post => post.id !== id));
      console.log('Post successfully deleted');

    } catch (error) {
      console.error('Delete failed:', error);
      alert(`Failed to delete post: ${error.message}`);
    }
  };

  const editPost = (id) => {
    const postToEdit = posts.find(post => post.id === id);
    setEditingPost(postToEdit);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditingPost((prev) => ({ ...prev, [name]: value }));
  };

  const saveEdit = async () => {
    console.log('Starting saveEdit function with ID:', editingPost?.id);
    
    if (!editingPost?.id) {
      console.error('No valid post to update');
      return;
    }
  
    try {
      // Update in Supabase
      const { error } = await supabase
        .from('posts')
        .update({
          title: editingPost.title,
          author: editingPost.author,
          date: editingPost.date,
          category: editingPost.category,
          content: editingPost.content
        })
        .eq('id', editingPost.id);
  
      if (error) throw error;
  
      // Fetch fresh data immediately after update
      const { data: freshData, error: fetchError } = await supabase
        .from('posts')
        .select('*')
        .order('date', { ascending: false });
  
      if (fetchError) throw fetchError;
  
      // Update local state with fresh data
      setPosts(freshData);
      setEditingPost(null);
  
    } catch (error) {
      console.error('Update failed:', error);
      alert(`Failed to update post: ${error.message}`);
    }
  };

  return (
    <Router>
      <GlobalStyles />
      <Navbar
        isAdmin={isAdmin}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Home
              posts={posts}
              deletePost={isAdmin ? deletePost : null}
              editPost={isAdmin ? editPost : null}
            />
          }
        />
        <Route path="/about" element={<About />} />
        {isAdmin && <Route path="/new-post" element={<NewPost addPost={addPost} />} />}
        <Route path="/post/:id" element={<PostDetails />} />
      </Routes>

      {editingPost && isAdmin && (
        <EditFormModal
          formData={editingPost}
          handleChange={handleChange}
          saveEdit={saveEdit}
          cancelEdit={() => setEditingPost(null)}
        />
      )}
    </Router>
  );
}

export default App;