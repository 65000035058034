import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { supabase } from '../supabase';
import ReactMarkdown from 'react-markdown';

const MainContainer = styled.main`
  width: 100%;
  min-height: 100%;
  padding: 50px 0;
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
`;

const Title = styled.h1`
  color: #222;
  font-size: 2.5em;
  margin-bottom: 10px;
`;

const AuthorAndDate = styled.p`
  color: #888;
  font-size: 0.9em;
  margin-bottom: 20px;
  font-style: italic;
`;

const Category = styled.p`
  color: #6a1b9a;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Content = styled.div`
  line-height: 1.6;
  color: #333;
  font-family: 'Poppins', sans-serif;
  
  p {
    margin-bottom: 1em;
  }
  
  strong {
    font-weight: bold;
  }
  
  em {
    font-style: italic;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #6a1b9a;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
  margin: 50px auto;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 100px);
  width: 100%;
`;

function PostDetails() {
  const { id } = useParams(); // Use 'id' from the URL
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        console.log('Fetching post with id:', id); // Log the id value
        const { data, error } = await supabase
          .from('posts')
          .select('*')
          .eq('id', id)
          .single();
  
        if (error) throw error;
        setPost(data);
        console.log('Post data:', data); // Log the fetched post data
      } catch (error) {
        console.error('Error fetching post:', error.message);
        setError('Post not found');
      } finally {
        setLoading(false);
      }
    };
  
    fetchPost();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [id]);

  if (loading) {
    return (
      <MainContainer>
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      </MainContainer>
    );
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!post) {
    return <p>Loading...</p>;
  }

  return (

      <MainContainer>
        <Container>
          <Category>{post?.category}</Category>
          <Title>{post?.title}</Title>
          <AuthorAndDate>{post?.author} / {post?.date}</AuthorAndDate>
          <Content>
            <ReactMarkdown>{post?.content}</ReactMarkdown>
          </Content>
        </Container>
      </MainContainer>
  
  );
}

export default PostDetails;
